/*------------------------------------------YOUTUBE VIDEO-------------------------------*/

.yt-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
}

label li {
  list-style-type: circle;
}
.accelerate-style {
  font-weight: 900;
  color: #fff;
  transition: 0.4s;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.test {
}

.test span {
  margin: 0px 5px 0px 5px;
}

.control-style {
  position: absolute;
  height: fit-content;
  width: 100%;
  padding: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  z-index: 1000;
}

.control-style i {
  margin: 0px 10px 0px 10px;
}

/*-------------------------------------------SIGNUP & SIGNIN-------------------------------*/

.enter-form .input-group span {
  background-color: #c4e0c6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: rgb(32, 32, 32);
  margin: 5px 0px 5px 0px;
  padding: 2px 10px 2px 10px;
}

.enter-form .input-group span i {
  color: #12703e;
}

.enter-form .form-control {
  margin: 5px 0px 5px 0px;
}

.input-eye .icon-eye {
  color: #12703e;
  position: absolute;
  right: 1.5vh;
  bottom: 1.5vh;
  z-index: 4;
}

.whats-next {
  margin-top: 1.5em;
  background-color: #f1f1f1;
  border-radius: 5px;
  box-shadow: 4px 4px 5px #aaaaaa;
}

.whats-next h1 {
  text-transform: none;
  font-size: 16px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 8px 0px 8px 0px;
  background-color: #39a56b;
  color: #fff;
  text-align: center;
  margin-bottom: 0.5em;
}

.whats-next label {
  padding: 10px;
  font-size: 15px;
}

/*-------------------------------------------ENROLL NOW BUTTOM-------------------------------*/

.pushable {
  margin-top: 20px;
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(340deg 100% 16%) 0%,
    hsl(340deg 100% 32%) 8%,
    hsl(340deg 100% 32%) 92%,
    hsl(340deg 100% 16%) 100%
  );
}
.front {
  display: block;
  position: relative;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 14px;
  text-transform: capitalize;
  color: white;
  background: hsl(345deg 100% 47%);
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.pushable:hover {
  filter: brightness(110%);
}
.pushable:hover .front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}
.pushable:active .front {
  transform: translateY(-2px);
  transition: transform 34ms;
}
.pushable:hover .shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}
.pushable:active .shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}
.pushable:focus:not(:focus-visible) {
  outline: none;
}

/*-------------------------------------------ALL-------------------------------*/

/* change ighlight colr*/
::selection {
  background: #196e4173;
}

.divider {
  border-bottom: 3px dotted #196e41;
  margin: 50px 0px 50px 0px;
}

textarea {
  height: 50px;
}

/*------------------------------------------LOADER -------------------------------*/

/* .loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #196e4193; 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */

.loader {
  color: #12703e;
  font-size: 10px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

/*-----------------------------------GENERAL------------------------------------*/

body {
  font-family: "Quicksand";
}

.table {
  text-align: center;
}

.table th {
  font-size: 13px;
}

.table thead {
  background-color: #eeeeee;
  color: #000;
}

span {
  cursor: pointer;
}

.nav-item .btn {
  color: #333333;
  font-size: 15px;
  font-weight: 600;
}

.nav-item .btn:hover {
  color: #161616;
  cursor: pointer;
}

.nav-item .btn:focus {
  color: #161616;
}

.border-style {
}

.marks-success {
  border-radius: 100%;
  box-shadow: 4px 4px 5px #aaaaaa;
  width: 100px;
  height: 100px;
  background-color: #39a56b;
  color: #fff;
  font-size: 30px;
}

.marks-failed {
  border-radius: 100%;
  box-shadow: 4px 4px 5px #aaaaaa;
  width: 100px;
  height: 100px;
  background-color: #fd363a;
  color: #fff;
  font-size: 30px;
}

.section {
  padding: 50px;
}

.display-pdf {
  width: 100%;
  height: 80vh;
}

.min-height {
  min-height: 92vh;
}

.form-control {
  margin: 2px 2px 7px 2px;
  box-shadow: none;
}

.color {
  color: #ffe8bb;
}

.form-control:focus {
  border-color: #3b3b3b;
  box-shadow: unset;
}

.middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.middle-not-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.uppercase {
  text-transform: uppercase;
}

.page-bgstyle {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgb(255, 255, 255);
  margin: 40px 20px 40px 20px;
  padding: 30px;
  background-color: #fff;
  border-radius: 5px;
}

.page-bgstyle2 {
  margin: 20px;
  padding: 30px;
}

.main {
}

.main .row img {
  margin: 10px;
}

/*-------------------------------------------------BUTTON------------------------------------------*/

.dropdown-menu {
  font-size: 13.5px;
  border: none;
  background-color: #f1f1f1;
}

.dropdown-item.active {
  /*ubah dropdown active color bg*/
  background-color: transparent;
  color: #1d1d1d;
  font-weight: 700;
}
.dropdown .dropdown-menu .dropdown-item:active,
.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgb(190, 190, 190) !important;
  color: #1d1d1d;
}

.dropdown .dropdown-menu .submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}
.dropdown .dropdown-menu .submenu-left {
  right: 100%;
  left: auto;
}
.dropdown-menu > li:hover > .submenu {
  background-color: #e4e4e4;
}
.dropdown .dropdown-menu > li:hover > .submenu {
  display: block;
}

form .btn {
  margin-left: 0px;
}
.btn:focus {
  box-shadow: none;
}

.btn-enroll-disable {
  background-color: #a1a1a1;
  color: #fff;
  padding: 12px 20px 12px 20px;
  transition: 0.4s;
  margin: 10px;
  font-size: 14px;
  border-radius: 5px;
}

.btn-enroll-disable:hover {
  background-color: #a1a1a1;
  cursor: default;
  color: #fff;
}

.btn-disable {
  background-color: #a1a1a1;
  color: #fff;
  padding: 8px 20px 8px 20px;
  transition: 0.4s;
  text-transform: capitalize;
  margin: 10px;
  font-size: 14px;
  border-radius: 30px;
}

.btn-disable:hover {
  background-color: #a1a1a1;
  cursor: default;
  color: #fff;
}

.all-btn {
  /*dulu hijau*/
  box-shadow: 4px 4px 5px #aaaaaa;
  background-color: #239156;
  color: #fff;
  padding: 10px 25px 10px 25px;
  font-size: 13px;
  transition: 0.4s;
  text-transform: capitalize;
  margin: 10px;
  border-radius: 30px;
  border: none;
}

.all-btn:hover {
  background-color: #12703e;
  color: #fff;
}

.all-btn:focus {
  background-color: #12703e;
  color: #fff;
}

.dashboard-left .btn-active {
  box-shadow: 4px 4px 5px #aaaaaa;
  background-color: #fff;
  font-weight: 700;
  color: #12703e;
}

.faq {
}

.faq .btn-active {
  box-shadow: 4px 4px 5px #aaaaaa;
  background-color: #fff;
  border: 2px solid #196e41;
  font-weight: 700;
  color: #196e41;
}

.mapdata {
}

.mapdata .btn-active {
  box-shadow: 4px 4px 5px #aaaaaa;
  background-color: #fff;
  border: 2px solid #12703e;
  font-weight: 700;
  color: #12703e;
}
.btn-outline-black {
  box-shadow: 4px 4px 5px #aaaaaa;
  font-size: 13px;
  padding: 5px 10px 5px 10px;
  transition: 0.4s;
  text-transform: capitalize;
  margin: 10px;
  color: #000;
  border: 2px solid #000;
}

.btn-outline-black:hover {
  animation: shake 1s alternate infinite;
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.btn-outline-black:focus {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.btn-outline-red {
  box-shadow: 4px 4px 5px #aaaaaa;
  font-size: 13px;
  padding: 5px 10px 5px 10px;
  transition: 0.4s;
  text-transform: capitalize;
  margin: 10px;
  color: #dd383b;
  border: 2px solid #dd383b;
}

.btn-outline-red:hover {
  animation: shake 1s alternate infinite;
  background-color: #dd383b;
  border-color: #dd383b;
  color: #fff;
}

.btn-outline-red:focus {
  background-color: #dd383b;
  border-color: #dd383b;
  color: #fff;
}

/* 
.border-black {
  border: 3px solid #000;
}

.text-red-style {
  border:2px solid #ce0a0e;
  padding:3px 20px 3px 20px;
  border-radius: 5px;
}  */

.text-bold {
  color: #000;
  font-weight: 800;
}

.text-red {
  color: #dc3545;
  font-weight: 700;
}

.text-white {
  color: #ffffff;
}

.text-pink {
  color: #ff6366;
  font-weight: 700;
}

.text-blue {
  color: #629bd1;
  font-weight: 700;
}

.text-green {
  color: #16864a;
  font-weight: 700;
}

.text-yellow {
  color: #ffc757;
}

.text-lightgrey {
  color: #c7c7c7;
}

.text-grey {
  color: #818181;
}

.text-darkgrey {
  color: #3b3b3b;
}

.style-pink {
  background-color: #ff6366;
  padding: 5px;
  border-radius: 3px;
  color: #fff;
}

.style-yellow {
  background-color: #ffce85;
  padding: 5px;
  border-radius: 3px;
  color: #fff;
}

.style-green {
  background-color: #239156;
  padding: 5px;
  border-radius: 3px;
  color: #fff;
}

.bg-blue {
  background-color: #3b9bf5;
}

.bg-black {
  background-color: #000;
}

.bg-green-dark {
  background-color: #196e41;
}

.bg-green {
  background-color: #239156;
}

.bg-white {
  background-color: #ffffff;
}

.bg-pink {
  background-color: #ff6366;
}

.bg-lightyellow {
  background-color: #ffe8bb;
}

.bg-yellow {
  background-color: #ffc757;
}

.bg-orange {
  background-color: #ff9f44;
}

.bg-lightgrey {
  background-color: #e7e7e7;
}

.bg-disable {
  background-color: #a1a1a1;
}

.bg-darkgrey {
  background-color: #3b3b3b;
}

.bg-red {
  background-color: #fd3c3f;
}

.bg-purple-dark {
  background-color: #b27acc;
}

.bg-purple {
  background-color: #c494da;
}

h1 {
  font-size: 40px;
  color: #000;
  text-transform: uppercase;
}

h2 {
  font-size: 35px;
  color: #000;
  text-transform: uppercase;
}

h3 {
  font-size: 22px;
  color: #000;
  text-transform: uppercase;
}

h4 {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h5 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h6 {
  font-size: 16px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
}

p,
ul,
label,
tbody {
}

a {
  color: rgb(37, 37, 37);
}

a:hover {
  color: #000;
  /* text-decoration: none; */
}

.myenrollments-card a:hover {
  background-color: transparent;
  transition: none;
}

.title-border {
  width: 50px;
  height: 2px;
  background-color: #196e41;
  margin: 5px 0px 30px 0px;
}

.home-title {
  color: #fff;
  margin-bottom: 20px;
  font-size: 20px;
}

/*-------------------------------------------------TAB------------------------------------------*/
.tab {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  overflow: hidden;
  border: 2px solid #239156;
  border-bottom: none;
  background-color: #239156;
  font-size: 13px;
  margin-top: 20px;
}

.tab a {
  text-decoration: none;
  color: unset;
}

.tab a {
  color: #fff;
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.5s;
}

.tab a:hover {
  background-color: #12703e;
}

.tab a.active {
  background-color: #fff;
  font-weight: 700;
  color: #239156;
}

.tabcontent {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px;
  border: 2px solid #239156;
  border-top: none;
}

/*-------------------------------------------------CAROUSEL------------------------------------------*/
.carousel-img-holder {
  background-color: #fff;
  margin-bottom: 40px;
}

/* .carousel-width {
  height: 500px;
} */

.carousel-arrow {
  font-size: 30px;
  color: "#196e41";
  color: #33333349;
  padding: 5px;
  animation: 1s;
}

.carousel-arrow:hover {
  color: #161616;
}

.carousel-enroll {
}

.carousel-enroll .carousel-arrow {
  color: rgb(66, 66, 66);
}

.carousel-enroll .carousel-arrow:hover {
  color: #333333;
}

button.rec-dot {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 1px 3px rgba(255, 255, 255, 0.5);
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
  box-shadow: 0 0 1px 3px rgba(17, 17, 17, 0.5);
}

/*-------------------------------------------------ADMIN------------------------------------------*/
/* .admin {
  min-height: 90vh;
}

.admin h3 {
  padding-top: 50px;
  margin-left: 25px;
  color:#000;
}

.admin .title-border {
  background: #196e41;
  height:3px;
} */

/* .admin-sidebar {
  padding:20px 0px 50px 0px;
  min-height: 70vh;
}

.admin-sidebar .btn {
  width:60%;
} */
/* 
.main {
  background-color: #fff;
} */

/*----------------ADMMIN CLASS----------------------*/

.batch {
  background-color: #fff;
  padding: 20px;
}

.class {
}

.class .mymodal .detail .col {
  background-color: #ffce85;
  border-radius: 5px;
  margin: 10px;
  padding: 20px;
}

.class .class-title {
  margin: 20px 0px 10px 0px;
  font-weight: 900;
}

.class .detail-btn {
  background-color: #ececec;
  width: 100%;
  padding: 10px 30px 10px 30px;
  text-align: left;
}

.class .detail-btn label {
  font-weight: 700;
  font-size: 15px;
}

.class .content {
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
}

.class-part-detail {
}

.class-part-detail .col {
  background-color: #3fad735e;
  padding: 30px 10px 30px 10px;
  margin: 10px;
  border-radius: 5px;
}

/*----------------ENROLLMENT COUNT----------------------*/

.ecount .col {
  padding: 5px;
  margin: 20px;
  text-align: center;
  border-radius: 5px;
}

/*----------------DASHBOARD USER & ADMIN PAGE----------------------*/
.dashboard {
  margin: 30px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-left {
  background-color: #fff;
  padding: 20px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 4px 4px 5px #aaaaaa;
}

.dashboard-right {
  background-color: #fff;
  padding: 30px;
}

/*----------------SECTION 1 HEADER----------------------*/
.s1 {
  min-height: 55vh;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #f5f5f5; */
  padding: 50px;
}

.s1 a {
  text-decoration-line: none;
  color: #fff;
}

.s1 .style {
  background-color: #ff6366;
  color: #fff;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 2px;
}

.s1 span {
  cursor: default;
}

.s1-left {
  position: absolute;
  z-index: 1;
  background-color: #ffce85;
  padding: 20px;
  width: 250px;
  height: 250px;
  top: 1vh;
  left: 1vh;
  border-radius: 100%;
  animation-delay: 1s;
  animation: bounce 1s alternate infinite;
}

.s1-left-shadow {
  position: absolute;
  z-index: 0;
  background-color: #3b3b3b;
  padding: 20px;
  top: 22vh;
  left: 22vh;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  animation-delay: 1s;
  animation: bounce 1s alternate infinite;
}

.s1-right {
  position: absolute;
  z-index: 1;
  background-color: #ffce85;
  padding: 20px;
  top: 1vh;
  left: 1vh;
  width: 250px;
  height: 250px;
  border-radius: 5px;
}

.s1-right-shadow {
  position: absolute;
  z-index: 0;
  background-color: #3b3b3b;
  padding: 20px;
  top: 20vh;
  width: 250px;
  height: 250px;
  left: 153vh;
  border-radius: 5px;
  animation: blink 1s alternate infinite;
}

.s1-left h3 {
  background-color: #fff;
  color: #3b3b3b;
  font-weight: 700;
  padding: 3px;
  border-radius: 2px;
}

.s1-right h3 {
  background-color: #ffff;
  color: #3b3b3b;
  font-weight: 700;
  padding: 3px;
  width: 150px;
  border-radius: 2px;
}

.s1-right-shadow label {
  color: #3b3b3b;
}

.s1-left-shadow label {
  color: #3b3b3b;
}

.s1-right span {
  cursor: pointer;
  color: #3b3b3b;
  animation: shake 1s alternate infinite;
}

.s1-right a {
  color: #3b3b3b;
  font-weight: 700;
}

/*-------------------SECTION 2-------------------*/

.s2 {
  padding: 50px;
  background-color: #fff;
}

.s2-container {
  margin-top: 10px;
  display: flex;
  list-style: none;
}

.s2-box {
  color: #000;
  border-radius: 100%;
  font-size: 15px;
  margin: 10px;
  width: 120px;
  height: 120px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 1s;
}

.s2-box:hover {
  transform: rotate(360deg);
}

.s2-box i {
  font-size: 18px;
  color: #3b3b3b;
  border-radius: 100%;
}

/*---------------------SECTION PRODUCT----------------------*/
.s-product {
  margin-top: 50px;
}

/*--------------JAKIM (section 5)---------------*/
.s5 {
  background-color: #000000;
}

/*-------------------------------------------------------HOMEPAGE / PAGE PRORAMS && COURSES ----------------------------------------------------------*/

.product-header {
  box-shadow: 3px 3px 10px rgb(97, 97, 97);
  background: #16864a;
  color: #fff;
  position: relative;
  margin: 2em;
  text-transform: capitalize;
  padding: 7px;
  width: 40%;
  font-size: 16px;
}

.product-header:before {
  content: "";
  display: block;
  position: absolute;
  top: -0.05em;
  left: -1.2em;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #16864a;
}

.product-header:after {
  content: "";
  display: block;
  position: absolute;
  top: -0.05em;
  right: -1.2em;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #16864a;
}

.programs-btn {
  padding: 20px;
  text-align: right;
  background-color: #3a855d;
  background-image: linear-gradient(to right, #126639, rgb(52, 190, 98));
}

.programs-btn a {
  color: #fff;
}

.programs-card {
  background-color: #fff;
  border-radius: 5px;
  transition: 0.5s;
  margin: 10px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  overflow: hidden;
}

.programs-card img {
  /* border-radius: 2%; */
}

.programs-card p {
  margin-top: 20px;
}

.courses {
  /* margin: 50px 0px 50px 0px; */
  min-height: 90vh;
}

.courses-card {
  background-color: #fff;
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgb(255, 255, 255);
  /* box-shadow: 2px 4px 8px 2px rgba(21, 131, 39, 0.404); */
  margin: 5px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  overflow: hidden;
}

.courses-card li a {
  color: #000;
}

.courses-card .btn {
  width: 100%;
  margin: 20px 0px 20px 0px;
}

.courses-card h1,
h3 {
  text-align: center;
  margin: 10px 0px 10px 0px;
}

.courses-card h1 {
  font-family: "Changa One", cursive;
  color: #1b1b1b;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.courses-card h3 {
  margin-bottom: 30px;
  font-size: 18px;
  color: #3b3b3b;
  text-transform: capitalize;
}

.course-detail .col {
  padding: 20px;
  border-bottom: 5px double #b1b1b1;
  background-color: #f7f7f7;
}

.course-detail h1 {
  font-family: "Changa One", cursive;
  color: #333333;
}

/* .course-detail .btn {
  margin: 30px 0px 0px 0px;
  height: 40px;
  width:100%;
} */

.detail-btn {
  background-color: #f7f7f7;
  width: 100%;
  padding: 10px;
  text-align: left;
}

.course-detail label {
  font-weight: 700;
  font-size: 15px;
}

.course-detail .detail-btn i {
  font-size: 20px;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.course-detail .content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.course-detail .content .detail-btn {
  background-color: #f7f7f7;
}

/*------------------------------HIDE INFO ONCLICK---------------------*/

.btn-hide {
  width: 100%;
  margin: 0px;
  height: 50px;
  border-radius: 0px;
  text-align: left;
  background-color: #ececec;
  font-size: 18px;
  text-transform: capitalize;
  color: #3b3b3b;
}

.btn-hide:hover {
  background-color: #ffdfae;
  color: #3b3b3b;
}

.btn-hide:focus {
  background-color: #ffdfae;
  color: #3b3b3b;
}

.btn-hide i {
  float: right;
  margin-right: 30px;
}

.card-hide {
  border: 5px solid #ececec;
  width: 90%;
}

/*---------------------------------------------POPUP CONTACT US-------------------------------------*/
.contact-us {
}

.contact-us .column {
  border-radius: 10px;
  background-color: #16864a;
  box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 30px 0px 0px 0px;
  color: #fff;
}

.contact-us .column span {
  padding: 8px 20px 8px 20px;
  background-color: #fff;
  color: #12703e;
  margin: 0px 10px 0px 10px;
}

.contact-us .column .bg-white {
  padding: 20px;
  border-radius: 10px;
}

/*---------------------------------------------PAGE ABOUT HALAL ACADEMY-------------------------------------*/
.about-circle {
  display: flex;
  list-style: none;
}

.about-box {
  color: #000;
  border-radius: 100%;
  box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 20px;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 0.5s;
}

.about-box i {
  font-size: 20px;
  color: #16864a;
}

.about-detail {
  margin-top: 20px;
}

.about-detail .col {
  color: rgb(46, 46, 46);
  background-color: #b9b9b9;
  background-image: linear-gradient(315deg, #d6d6d6 0%, #c9c9c9 74%);
  box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about-detail i {
  font-size: 20px;
}

/*---------------------------------------------HALAL INFO-------------------------------------*/
.halal-info {
}

.halal-info .card .col {
  /* box-shadow: 2px 4px 8px 2px rgba(0, 0, 0, 0.404); */
  background-color: #3a855d;
  background-image: linear-gradient(to right, #ffe884, #fff293);
  color: #000;
  font-size: 15px;
  padding: 20px;
  height: 180px;
  width: 180px;
  margin: 2px;
  border-radius: 5px;
}

.halal-info .row .card i {
  color: #12703e;
  background-color: #fff;
  padding: 10px;
  border-radius: 100%;
  font-size: 20px;
}

/*---------------------------------------------MALAYSIA HALAL CERTIFICATION -- NEW = HALAL INDUSRTY-------------------------------------*/

.mhc {
  text-align: left;
}

.mhc label {
  font-weight: bold;
  background-color: #4eec7023;
  color: #126639;
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
  font-size: 15px;
}

/*--------------------------------------------CAREER------------------------------------*/

.career {
  text-align: left;
}

.career label {
  font-weight: bold;
  background-color: #4eec7023;
  color: #126639;
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
  font-size: 15px;
}

.career a {
  background-color: #126639;
  background-image: linear-gradient(to right, #126639, rgb(52, 190, 98));
  padding: 10px;
  border-radius: 5px;
  color: #fff;
}

/*---------------------------------------------HOW IT WORKS-------------------------------------*/

.hiw-box {
  color: #272727;
  /* box-shadow: 4px 4px 5px #aaaaaa; */
  color: #333333;
  margin: 10px;
  border-radius: 10px;
}

.hiw-box .title {
  padding: 10px;
  font-weight: 900;
  font-size: 16px;
}

.hiw-box p {
  text-align: justify;
}

.hiw-box span {
  font-size: 30px;
}

.hiw-box .title-border {
  background-color: #16864a;
}

/*---------------------------------------------HELP-------------------------------------*/

.help {
}

.help .card {
  margin: 5px;
  background-color: #cacaca;
  background-image: linear-gradient(315deg, #d6d6d6 0%, #bbbbbb 74%);
  padding: 20px;
}

.help .card i {
  color: #000;
  margin-right: 10px;
}

.help .card a {
  color: #000;
}

/*---------------------------------------------USER DASHBOARD -------------------------------------*/

.user-dashboard-header {
  font-weight: 100;
  text-transform: lowercase;
  padding: 10px;

  margin: 10px;
  width: 500px;
}

/*---------------------------------------------ENROLLMENT DASHBOARD (NEW=MY ENROLLMENTS)-------------------------------------*/

.btn-back {
  font-size: 12px;
  padding: 2px 8px 2px 8px;
  z-index: 1;
  margin-bottom: 20px;
}

.myenrollments-box {
  margin-top: 10px;
}

.course {
}

.learning {
}

.learning .page-header {
  background-color: transparent;
  background-image: none;
  padding: 20px;
}

.learning .page-header b {
  font-weight: bold;
  background-color: #4eec7023;
  color: #126639;
  padding: 5px 20px 5px 20px;
  border-radius: 20px;
  font-size: 15px;
}

.learning .page-header p {
  margin: 5px 0px 0px 5px;
  font-style: italic;
}

.enroll-card {
  margin: 5px;
  padding: 0px;
  text-align: left;
  background-color: #fff;
  box-shadow: 3px 3px 10px rgb(185, 185, 185);
}

.enroll-card .card {
  padding: 0px;
}

.enroll-title {
  color: #000;
  padding: 20px;
  background-color: rgb(245, 245, 245);
  text-align: center;
  border-top: 10px solid #126639;
  /* border-bottom: 4px solid #12663965; */
  overflow: hidden;
}

.enroll-title h5 {
  color: #fff;
  text-transform: capitalize;
}

.enroll-title h3 {
  color: #fff;
  text-transform: capitalize;
}

.enroll-body {
  padding: 20px;
  /* border-left: 3px solid#239156;
  border-right: 3px solid#239156; */
}

.enroll-status {
  text-align: center;
  padding: 10px;
  color: #1d1d1d;
  box-shadow: 3px 3px 10px rgb(185, 185, 185);
  overflow: hidden;
}

.enroll-body .request-btn {
  background-color: #3a855d;
  background-image: linear-gradient(to right, #126639, rgb(52, 190, 98));
  padding: 7px 20px;
  color: #fff;
  border-radius: 20px;
  font-size: 14px;
}

/* .enroll-count {
  color: #000;
  /* background-color: #f3f3f3; 
  border-top: 10px solid #f3f3f3;
  font-weight: 900;
}

.enroll-count p {
  margin-top: 20px;
}

.enroll-count label {
  font-size: 50px;
} */

.module {
  box-shadow: 3px 3px 10px rgb(185, 185, 185);
  margin: 5px;
  height: 100%;
  padding-bottom: 50px;
}

.module .enroll-module {
  text-align: left;
  padding: 20px;
  background-color: #fff;
}

.module .enroll-title {
  padding: 20px;
  color: #000;
  /* border-top-left-radius: 0px;
  border-top-right-radius: 10px; */
  border-top: 10px solid #196e41;
  border-bottom: 3px solid #196e414f;
  text-align: center;
}

.module .module-btn {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 4vh;
  padding-bottom: 10px;
}

.course .card {
  padding: 0px;
}

/* .myenrollments-card {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgb(255, 255, 255);
  border: 3px solid #3b3b3b;
  padding: 0px;
  margin: 20px;
}

.myenrollments-forBtn {
  font-size: 30px;
  height: auto;
  margin: 0;
  padding: 5px;
  background-color: #f0efef;
}

.myenrollments-card-title {
  padding: 20px;
  background-color: #f0efef;
  color: #000;
}

.myenrollments-card-title img {
  margin: 5px;
}

.myenrollments-card p {
  padding: 30px;
  font-weight: 600;
}

.myenrollments-card h5 {
  font-weight: 300;
} */

.req-class {
  position: absolute;
  z-index: 1;
  right: 2vh;
  top: 1vh;
  padding: 10px;
  cursor: default;
  font-size: 12px;
  animation: shake 2s infinite alternate;
  border-radius: 100%;
  width: 70px;
  height: 70px;
}

.black-and-white {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  color: #818181;
  border-color: #818181;
}

/*---------------------------------------------QUIZ-------------------------------------*/

.wrong-questions-review {
  margin-top: 10vh;
}

.wrong-questions-review h1 {
  text-transform: none;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  background-color: #ec4e51;
  color: #fff;
  border-radius: 5px;
}

.wrong-questions-review .alert {
  margin-bottom: 5vh;
}

.wrong-questions-review .question .listing {
  font-weight: bold;
}

.wrong-questions-review .question .list-icon {
  top: 2px;
  font-size: 14px;
}

/*---------------------------------------------LISTING ALIGNMMENT-------------------------------------*/

.listing {
  padding: 0 0 0 22px;
  position: relative;
}

.listing i,
.list-icon {
  left: 0;
  position: absolute;
  top:0em;
}

/*---------------------------------------------LEARNING PAGE-------------------------------------*/
.elearning {
}

.elearning-box {
  background-color: unset;
}

.elearning-topic {
  padding: 20px;
}

.elearning-video {
  padding: 20px;
}

.elearning-subtopic {
  padding: 20px;
}

.col-style {
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
}

.elearning-right {
  padding: 0px 20px 0px 20px;
  background-color: #fff;
  border-radius: 3px;
}

.elearning-topic,
.elearning-subtopic li {
  padding: 0;
  font-size: 15px;
  list-style: none;
}

.elearning-box a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.elearning-topic .col-style i {
  font-size: 12px;
}

.elearning-topic .col-style a {
  color: #fff;
}

.elearning-topic .col-style .listing {
  margin: 2px;
  color: #fff;
  padding: 10px 10px 10px 40px;
}

.elearning-topic .col-style .listing .list-icon {
  left: 18px;
  top: 15px;
}

/*--------------KNWOLEDGE ASSESSMENT---------------*/
/* .ka-timer{
  background-color: #ffce85;
  color: #fff;
  padding: 20px;
  border-radius: 100%;
  margin: 10px 10px 30px 10px;
  width:100px;
  height:100px;
} */

.timer {
}

.ka-question {
  font-weight: 600;
}

.ka-option {
}

.ka-answer {
}

/*--------------FOOTER---------------*/

.footer {
  background-color: #0f0f0f;
  color: #fff;
  padding: 20px;
  font-size: 14px;
}

.footer a {
  color: #fff;
  text-decoration: none;
  letter-spacing: 1px;
}

.footer a:hover {
  color: #fff;
  text-decoration: underline;
  letter-spacing: 1px;
}

/*----------------------------------------E-TICKET--------------------------------------------*/
.eticket-bold {
  text-transform: uppercase;
  font-size: 25px;
}

.eticket-light {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 300;
}

/*-------------------------- CHATTING-----------------------------*/

.chat-user {
}

.button-float-chat2 {
  /* background-color: #ff0000; */
  /* box-shadow: 4px 4px 5px #aaaaaa; */
  /* padding:10px; */
  position: fixed;
  z-index: 2;
  bottom: 19vh;
  right: 2vh;
  /* color:#fff; */
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* animation: shake 1s alternate infinite; */
}

.button-float-chat {
  position: fixed;
  z-index: 2;
  bottom: 3vh;
  right: 2.5vh;
  font-size: 20px;
  color: #fff;
  border-radius: 100%;
  width: 90px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: shake 1s alternate infinite;
}

.chat {
  font-size: 13px;
}

.chat-head {
  padding: 10px;
  color: #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.admin-col {
  margin: 5px;
  min-height: 60vh;
  overflow: auto;
}

.chat-content-main2 {
  height: 60vh;
  background-color: #dfdfdf;
  overflow: auto;
}

.chat-message {
  padding: 5px;
  margin: 5px;
  border-radius: 2px;
  display: table;
}

.chat-input {
  background-color: #d1d1d1;
  margin: 0px;
  padding: 10px 10px 10px 10px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.chat-attach {
  position: absolute;
  right: 7vh;
  bottom: 3vh;
  background-color: #fff;
  font-size: 15px;
}

.chat-list {
  background-color: #dfdfdf;
}

.list-user-card {
  background-color: #fff;
  margin-bottom: 2px;
  padding: 5px;
  border-radius: 2px;
}

.list-user-card:hover {
  background-color: #f3f3f3;
}

.chat-notification {
  position: absolute;
  top: 1vh;
  right: 7vh;
  width: 30px;
  height: 30px;
  background-color: #ec4e51;
  padding: 5px;
  color: #fff;
  border-radius: 100%;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.chat-list .form-control {
  font-size: 14px;
  padding: 3px;
}

.chat-search {
  padding: 10px;
  width: 100%;
}

/* --------------------------------------MODAL CSS------------------------------*/
/* MODAL NI DIA FROM LEFT*/
.mymodal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 5; /* Sit on top */
  padding-top: 50px; /* Location of the box */
  padding-bottom: 50px;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.mymodal-content {
  background-color: #fefefe;
  border-radius: 3px;
  border: 10px solid transparent;
  margin: auto;
  padding: 30px;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
  left: 0;
  top: 0;
  animation-name: leftFadeIn;
  animation-duration: 0.5s;
}

/* NORMAL MODAL NI DIA FROM LEFT: PREFER FOR USER POPUP MESEJ*/
.normalmodal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 5; /* Sit on top */
  padding-top: 50px; /* Location of the box */
  padding-bottom: 50px;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.normalmodal-content {
  background-color: #fefefe;
  border-radius: 3px;
  border: 10px solid transparent;
  margin: auto;
  padding: 30px;
  width: auto;
  height: auto;
  overflow: auto;
  position: relative;
  left: 0;
  top: 0;
  animation-name: bottomFadeIn;
  animation-duration: 0.5s;
}

.close {
  color: #272727;
  float: right;
  font-size: 40px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.remove {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
  top: 30px;
}

.remove:hover,
.remove:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/*-------------------------------BADGE---------------------*/

.badge {
  padding: 8px;
  margin: 3px;
  border-radius: 3px;
  font-weight: 100;
}

.badge .btn {
  padding: 0px;
  padding-left: 2px;
}

.badge-black {
  background-color: #000;
  color: #fff;
}

/*UTK PILIH MODULE DAN PILIH LAIN2 (course MANAGER)-BAHAGIAN ADMIN-*/
.border-black {
  border: 2px solid rgb(59, 59, 59);
  padding: 10px;
  width: 100%;
}

/* .border-black .fas{
  color: #fff;
} */

.course-manager {
}
.question-manager {
}
.course-progress {
}
.physical-class {
}

.course-manager .border-black {
  padding: 7px;
  margin: 2px;
  border: 1px solid rgb(190, 190, 190);
}

.question-manager .border-black {
  padding: 7px;
  margin: 2px;
  border: 1px solid rgb(190, 190, 190);
}

.course-progress .border-black {
  padding: 7px;
  margin: 2px;
  border: 1px solid rgb(190, 190, 190);
}

.physical-class .border-black {
  padding: 7px;
  margin: 2px;
  border: 1px solid rgb(190, 190, 190);
}

/* --------------------------------------NEW PAGES HEADER AND CONTENT STYLE------------------------------*/

.page-header {
  background-color: #fff;
  background-image: linear-gradient(to right, #126639, rgb(52, 190, 98));
  padding: 80px 0px 50px 0px;
  text-align: left;
}

.page-content {
  text-align: left;
  background-color: #fff;
  padding: 20px;
  min-height: 78vh;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  box-shadow: 4px 4px 5px #aaaaaa;
}

.page-header h2 {
  font-size: 20px;
}

.page-header h1 {
  font-weight: 900;
  font-size: 40px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.page-header h1,
h2 {
  color: #fff;
  text-transform: capitalize;
}

.page-content .alert {
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 4px 4px 5px #aaaaaa;
}

/* --------------------------------------SMALL WHEN MOBILE------------------------------*/
@media only screen and (max-width: 900px) {
  .enroll-body .request-btn {
    font-size: 12px;
    padding: 5px;
  }

  .page-header {
    padding: 30px 0px 30px 0px;
  }
  .page-header h1 {
    font-size: 25px;
  }

  .courses-card h1 {
    font-size: 1.5em;
  }

  .button-float-chat2 {
    width: 70px;
    height: 70px;
    bottom: 15vh;
  }

  .button-float-chat {
    width: 70px;
    height: 70px;
    animation: unset;
  }

  .enroll-count {
    display: none;
  }

  /* --------------------E-LEARNING-------------------- */

  .page-bgstyle {
    padding: 30px 10px 10px 10px;
  }

  .page-bgstyle .row {
    padding: 0px;
    margin: 0px;
  }

  /* .module .enroll-title {
    border-top-left-radius: 10px;
  }

  .module .enroll-module {
    border-bottom-left-radius: 10px;
  } */

  .elearning .col-style,
  .card {
    padding: 0px;
  }

  .nav-item .btn {
    margin: 5px;
    font-size: 14px;
  }

  .user-dashboard {
    margin: 10px;
  }

  .dashboard,
  .dashboard-left,
  .dashboard-right {
    margin: 5px;
  }

  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }

  p,
  b,
  ul,
  li,
  label,
  tbody,
  th,
  span,
  em {
    font-size: 11px;
  }

  .nav-item .btn {
    font-size: 12px;
    padding: 2px;
  }

  .ecount h4 {
    font-size: 14px;
  }

  .ecount h5 {
    font-size: 14px;
  }

  .s2-box {
    width: 100px;
    height: 100px;
  }

  .s1 {
    min-height: 50vh;
  }

  /* .admin-sidebar {
    min-height: 0vh;
  }

  .admin-sidebar .btn {
    width:40%;
  } */

  .myenrollments-card {
    margin: 10px;
  }

  .myenrollments-card p {
    padding: 10px;
  }

  .all-btn,
  .btn-disable,
  .btn-black,
  .btn-outline-green,
  .btn-outline-black,
  .btn-outline-red {
    padding: 7px 12px 7px 12px;
    font-size: 11px;
    margin: 5px;
  }

  .about-box {
    margin: 10px;
    width: 90px;
    height: 90px;
    padding: 10px;
  }

  .about-box i {
    font-size: 13px;
  }

  .about-detail {
    padding: 5px;
    font-size: 11px;
  }

  .mhc-box1 {
    padding: 20px;
    margin: 10px;
    width: 80px;
    height: 80px;
    border-radius: 5px;
  }

  .help .btn-outline-green {
    margin-right: 0px;
  }

  .user-dashboard-header {
    width: 90%;
  }

  .footer h6 {
    font-size: 12px;
  }

  .footer p {
    font-size: 12px;
  }

  .nav-item .btn {
    margin-top: 0px;
  }

  .badge {
    padding: 3px;
    font-size: 10px;
  }

  .help .btn {
    width: 100px;
    height: 100px;
    margin: 5px;
  }
  .s1-left-shadow {
    position: unset;
    z-index: 2;
    background-color: unset;
    padding: unset;
    width: 200px;
    height: 100px;
    top: unset;
    left: unset;
    border-radius: 4px;
    animation-delay: unset;
    animation: unset;
    margin: unset;
  }
  .courses-card {
    margin: 20px 50px 20px 50px;
  }

  .form-control {
    padding: 0px;
    margin: 3px;
    font-size: 11px;
    padding: 3px;
    color: #000;
  }

  .btn-hide {
    font-size: 12px;
  }

  .class .col {
    margin: 20px 50px 20px 50px;
  }

  .batch {
    padding: 10px;
  }

  .chat-list,
  .admin-chat,
  .user-chat {
    display: none;
  }
}

@media only screen and (max-width: 1400px) {
  .enroll-body .request-btn {
    font-size: 12px;
    padding: 5px;
  }

  .page-header {
    padding: 30px 0px 30px 0px;
  }
  .page-header h1 {
    font-size: 25px;
  }

  .nav-item .btn {
    margin: 5px;
    font-size: 14px;
  }
  .user-dashboard {
    margin: 30px;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 25px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 17px;
  }

  .all-btn,
  .btn-disable,
  .btn-black,
  .btn-outline-green,
  .btn-outline-black,
  .btn-outline-red {
    padding: 7px 12px 7px 12px;
    font-size: 13px;
    margin: 5px;
  }

  .courses-card {
    margin: 10px;
  }

  p,
  b,
  ul,
  li,
  label,
  tbody,
  th,
  span,
  em {
    font-size: 14px;
  }

  .admin-chat .chat-input {
    top: 0vh;
  }

  .s1 h3 {
    font-size: 15px;
  }
  .s1 label {
    font-size: 13px;
  }

  .s1-left-shadow {
    position: unset;
    background-color: unset;
    padding: unset;
    width: 170px;
    height: 150px;
    top: unset;
    left: unset;
    border-radius: 4px;
    animation-delay: unset;
    animation: unset;
    margin: unset;
  }

  .s1-right-shadow {
    position: unset;
    background-color: unset;
    padding: unset;
    width: 170px;
    height: 150px;
    top: unset;
    left: unset;
    border-radius: 4px;
    animation-delay: unset;
    animation: unset;
    margin: unset;
  }

  .s1-right {
    position: relative;
    background-color: #ffce85;
    color: #3b3b3b;
    width: 170px;
    height: 170px;
    top: 2vh;
    left: 3vh;
    border-radius: 4px;
    animation-delay: unset;
    animation: unset;
  }

  .s1-left {
    position: relative;
    background-color: #ffce85;
    color: #3b3b3b;
    width: 170px;
    height: 170px;
    top: 2vh;
    left: 0vh;
    border-radius: 4px;
    animation-delay: unset;
    animation: unset;
  }

  .req-class {
    position: absolute;
    z-index: 1;
    right: 2vh;
    top: 1vh;
    padding: 10px;
    cursor: default;
    font-size: 10px;
    animation: unset;
    border-radius: 100%;
    width: 60px;
    height: 60px;
  }
}

.bounce {
  animation: bounce 1s alternate infinite;
}

.shake {
  animation: shake 1s alternate infinite;
}

@keyframes leftFadeIn {
  from {
    transform: translateX(-20rem);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    color: #196e41;
  }

  100% {
    color: transparent;
  }
}

@keyframes shake {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(1px, 1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, -1px) rotate(-1deg);
  }
  75% {
    transform: translate(2px, -1px) rotate(2deg);
  }
  100% {
    transform: translate(-1px, 1px) rotate(-1deg);
  }
}

@keyframes topFadeIn {
  from {
    transform: translateY(-10rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bottomFadeIn {
  from {
    transform: translateY(30rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes rightFadeIn {
  from {
    transform: translateX(30rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-30px);
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
